<template>
  <div class="d-flex justify-space-between">
    <div class="cr-w-370">
      <Title ref="title" v-on="$listeners" />
      <Description ref="desc" v-on="$listeners" />
      <MainViewTab ref="mainview" />
      <Secure ref="secure" />
    </div>

    <div v-if="!isModify" class="cr-w-370 cr-member">
      <Member ref="member" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-w-370 {
  width: 370px;
  &.cr-member {
    margin-left: 6px;
  }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    Title: () => import("./title"),
    Description: () => import("./description"),
    MainViewTab: () => import("./mainViewTab"),
    Member: () => import("./member"),
    Secure: () => import("./secure")
  },
  computed: {
    ...mapState("flowDialog", ["params", "isConfirmed"]),
    isModify() {
      return !!this.params?.project?.id;
    }
  },
  watch: {
    isConfirmed(isConfirmed) {
      if (!isConfirmed) return;

      this.confirm();
    }
  },
  methods: {
    ...mapMutations("flowDialog", ["SET_CONFIRMED", "CLOSE_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("flowProjects", ["addProject", "updateProject"]),
    confirm() {
      // TODO tags는 임시
      let params = { tags: "" };

      try {
        Object.keys(this.$refs).forEach(key => {
          const ref = this.$refs[key];
          if (!ref.getData) {
            return console.error("The confirm method is not defined.");
          }

          params = { ...params, ...ref.getData() };
        });
      } catch ({ message }) {
        this.openSnackbar({ message, type: "VALIDATION" });
        return this.SET_CONFIRMED(false);
      }

      if (!this.isModify) return this.addProject(params);
      const { id: projectId } = this.params.project;
      this.updateProject({ projectId, params });
    }
  }
};
</script>
